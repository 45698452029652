<template>
  <v-virtual-scroll
    :bench="1"
    :items="items"
    :height="height"
    item-height="72"
  >
    <template v-slot:default="{ item }">
      <v-list-item link @click="$emit('clickedItem', item)" two-line :key="item">
        <v-list-item-avatar class="elevation-1">
          <v-avatar size="56">
            <v-icon small>fas fa-cog</v-icon>
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1 secondary--text">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-body-2 grey--text text-lighten-3">
            {{ item.vendor_name }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text class="text-h6 secondary--text"
            >R$ {{ item.price }}</v-list-item-action-text
          >
        </v-list-item-action>
      </v-list-item>

      <v-divider/>
    </template>
  </v-virtual-scroll>
</template>

<script>
export default {
  props: {
    height: Number,
    items: {
      type: Array,
      required: true
    }
  }
}
</script>